import React from "react"
import styled from "@emotion/styled"
import { breakpoints, color, wrapper } from "../../utils/style"
import { graphql, Link, StaticQuery } from "gatsby"
import ButtonLink from "../shared/ButtonLink"
import FooterMenuCasinoPoipet from "../Menus/FooterMenuCasinoPoipet"
import FooterMenuCasinoOnline from "../Menus/FooterMenuCasinoOnline"
import FooterMenuDownloads from "../Menus/FooterMenuDownloads"
import FooterPlayerSupport from "../Menus/FooterPlayerSupport"
import LogoUpd from "./LogoSvg"
import Freshchat from "./Freshchat"
import Img from "gatsby-image"
import GetWindowWidth from "../shared/GetWindowWidth"

//import Logo from './Logo';

const FooterBlock = styled("footer")`
  background: rgba(0, 0, 0, 0.25);
  .top-footer-col {
    display: inline-block;
    vertical-align: top;
  }
  .top-footer-left {
    width: 30%;
    padding-right: 30px;
  }
  .top-footer-right {
    width: 70%;
    text-align: center;
    padding-top: 14px;
    ul {
      opacity: 0.6;
    }
  }
  .menu-col {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 0 10px 20px;
    text-align: left;
  }
  .menu-col-wide {
    width: 40%;
    padding: 0 10px 0 20px;
    ul {
      column-count: 2;
      column-gap: 2px;
    }
  }
  ul {
    margin: 20px 0;
    list-style: none;
    li {
      font-size: 14px;
      margin: 0 15px 10px 0;
    }
    a {
      color: ${color.white};
    }
    a:hover,
    a:active,
    .active {
      color: ${color.yellow};
      text-decoration: none;
    }
  }
  .h4 {
    font-weight: 600;
    font-size: 18px;
    color: ${color.gold};
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .logo {
    display: inline-block;
  }
  .footer-top {
    padding: 20px 0;
  }
  .footer-bottom-copyright {
    margin-bottom: 50px;
  }
  .footer-brands {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .footer-brands .gatsby-image-wrapper {
    margin-right: 25px;
  }
  .footer-brands .gatsby-image-wrapper:last-child {
    margin-right: 0;
  }
  @media (max-width: ${breakpoints.lg}) {
    padding: 0 20px;
  }
  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 50px;
    .footer-top {
      position: relative;
      padding-bottom: 10px;
    }
    .top-footer-left {
      width: 100%;
      padding: 0;
    }
    .top-footer-right {
      padding-top: 15px;
    }
    .top-footer-left,
    .top-footer-right {
      width: 100%;
      text-align: center;
    }
    .menu-col:nth-of-type(odd) {
      padding-left: 2px;
    }
    .menu-col:nth-of-type(4) {
      padding-right: 0;
    }
    .footer-bottom-copyright {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    .menu-col {
      width: 50%;
    }
    .menu-col-wide {
      padding: 0 0 0 10px;
      ul {
        column-count: 1;
        column-gap: 0;
      }
    }
    .footer-brands {
      justify-content: center;
    }
    .footer-brands .gatsby-image-wrapper {
      margin-right: 20px;
    }
  }
`

const Wrapper = styled("div")`
  ${wrapper}
`

const Copy = styled("div")`
  float: left;
  width: 31%;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  padding-right: 20px;
  span {
    opacity: 0.7;
  }
  a {
    margin: 0 0 0 20px;
    text-decoration: underline;
  }
  a:hover,
  a:active {
    text-decoration: none;
  }
  @media (max-width: ${breakpoints.sm}) {
    padding-right: 0;
    float: none;
    width: 100%;
    margin-top: 20px;
  }
`

const FooterButtons = styled("div")`
  padding: 10px 0;
  .col {
    display: inline-block;
    vertical-align: top;
    width: 45%;
  }
  .col-right {
    width: 54%;
  }
  .line {
    padding: 3px 0 3px 15px;
  }
  .payment-span {
    display: inline-block;
    background: ${color.transparentWhite2};
    height: 50px;
    padding: 1px 0;
    border-radius: 5px;
    margin: 0 10px;
    vertical-align: middle;
  }
  .top-line {
    margin-bottom: 16px;
  }
  .button {
    display: block;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 1rem;
  }

  @media (max-width: ${breakpoints.lg}) {
    .col {
      width: 87%;
    }
    .line {
      display: flex;
      margin: 0 0 10px;
      padding: 0;
      justify-content: space-between;
    }
    .payment-span {
      flex: 0 0 48%;
      margin: 0;
      text-align: center;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .col {
      width: 100%;
    }
    .payment-span {
      flex: 0 0 49%;
      margin-bottom: 5px;
    }
    .button {
      margin-bottom: 15px;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    .col {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .payment-span {
      flex: 0 0 48%;
    }
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      query {
        gclub: file(relativePath: { eq: "footer/gclub.png" }) {
          childImageSharp {
            fixed(width: 31) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        royal_online: file(relativePath: { eq: "footer/royal_online.png" }) {
          childImageSharp {
            fixed(width: 116) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        eighteen_plus: file(relativePath: { eq: "footer/eighteen_plus.png" }) {
          childImageSharp {
            fixed(width: 55) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        bank: file(relativePath: { eq: "footer/bank.png" }) {
          childImageSharp {
            fixed(width: 29) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        leaflet: file(relativePath: { eq: "footer/leaflet.png" }) {
          childImageSharp {
            fixed(width: 21) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        siam_commercial: file(
          relativePath: { eq: "footer/siam_commercial.png" }
        ) {
          childImageSharp {
            fixed(width: 29) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        bank_two: file(relativePath: { eq: "footer/bank_two.png" }) {
          childImageSharp {
            fixed(width: 29) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const windowWidth = GetWindowWidth()
      const isDesktop = windowWidth > 600
      return (
        <FooterBlock>
          <div className="footer-top">
            <Wrapper>
              <div className="top-footer-col top-footer-left">
                <LogoUpd to="/" text="holidaypalace.net" />

                <FooterButtons className="footer-buttons">
                  <ButtonLink
                    to={process.env.LINE_ID}
                    external={true}
                    iconColor={color.white}
                    ariaText="Line"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button gold-button"
                    text={process.env.LINE_ID_TEXT}
                    iconName="line"
                    iconLeft={true}
                    iconSize={26}
                  />
                  <ButtonLink
                    to={`tel:${process.env.PHONE_TO_CALL}`}
                    external={true}
                    ariaText="Call"
                    className="button gold-button"
                    text={process.env.PHONE_TO_DISPLAY}
                    iconName="call-answer"
                    iconColor={color.white}
                    iconLeft={true}
                    iconSize={24}
                  />
                </FooterButtons>
              </div>
              <div className="top-footer-col top-footer-right">
                <div className="menu-col">
                  <div className="h4">Casino Poipet</div>
                  <FooterMenuCasinoPoipet />
                </div>
                <div className="menu-col menu-col-wide">
                  <div className="h4">Casino Online</div>
                  <FooterMenuCasinoOnline />
                </div>
                <div className="menu-col">
                  <div className="h4">Player Support</div>
                  <FooterPlayerSupport />
                </div>
                <div className="menu-col">
                  <div className="h4">Downloads</div>
                  <FooterMenuDownloads />
                </div>
              </div>
            </Wrapper>
          </div>
          <Wrapper>
            <div className="footer-bottom-copyright">
              <Copy>
                <span>
                  © 2016 - {new Date().getFullYear()} Holiday Palace Thailand
                  All Rights Reserved | {process.env.FRONTEND_DOMAIN}
                </span>
                <Link to="/sitemap/">Sitemap</Link>
              </Copy>
              {isDesktop ? (
                <div className="footer-brands">
                  <Img
                    alt={"footer icon"}
                    fixed={data.gclub.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                  <Img
                    alt={"footer icon"}
                    fixed={data.royal_online.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                  <Img
                    alt={"footer icon"}
                    fixed={data.eighteen_plus.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                  <Img
                    alt={"footer icon"}
                    fixed={data.bank.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                  <Img
                    alt={"footer icon"}
                    fixed={data.leaflet.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                  <Img
                    alt={"footer icon"}
                    fixed={data.siam_commercial.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                  <Img
                    alt={"footer icon"}
                    fixed={data.bank_two.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                </div>
              ) : (
                <div className="footer-brands footer-brands-mobile">
                  <div>
                    <Img
                      alt={"footer icon"}
                      fixed={data.gclub.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                    <Img
                      alt={"footer icon"}
                      fixed={data.royal_online.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                  </div>
                  <div>
                    <Img
                      alt={"footer icon"}
                      fixed={data.eighteen_plus.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                    <Img
                      alt={"footer icon"}
                      fixed={data.bank.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                    <Img
                      alt={"footer icon"}
                      fixed={data.leaflet.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                    <Img
                      alt={"footer icon"}
                      fixed={data.siam_commercial.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                    <Img
                      alt={"footer icon"}
                      fixed={data.bank_two.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                  </div>
                </div>
              )}
            </div>
          </Wrapper>
          <Freshchat />
        </FooterBlock>
      )
    }}
  />
)
