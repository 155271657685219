import React from "react";
import styled from "@emotion/styled";
import {breakpoints, color} from "../../utils/style";
import IcomoonIcon from '../shared/IcomoonIcon';

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);
  chatBox.classList.toggle('open');
  if (chatBox.classList.contains('open')) {
    chatIframe.src = process.env.FRESHCHAT_IFRAME_SRC;
  } else {
    chatIframe.src = 'about:blank';
  }
};

const Wrap = styled('div')`
  .live-chat {
    bottom: 10px;
    position: fixed;
    right: 10px;
    background: ${color.gold};
    box-shadow: ${color.goldButtonShadow};
    padding: 5px 30px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 35px;
    color: #000;
    cursor: pointer;
    outline:none;
    border:none;
    border-radius: 25px;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
  
  .live-chat-text {
    padding: 5px 10px;
    background: ${color.white};
    margin-top:-12px;
    line-height: 2;
    font-size: 14px;
    font-style: italic;
  }
    
  .live-chat-popup {
    height: 880px;
    width: 420px;
    position:fixed;
    right:4px;
    bottom:0;
    z-index:999;
    overflow:hidden;
    display: none;
      
    iframe {
      position: absolute;
      bottom: 0;
      right: -5px;
      width: 1280px;
      height: 730px;
      border: 0;
    }
  }
  
  .above-chat {
    display: none;
  }
    
  &.open {
    bottom: 0;
    .live-chat-popup {
      display: block;
    }
    .live-chat {
      display: none;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    .live-chat { display: none; }
    .live-chat-popup {
      position: fixed;
      height: 100%;
      width: 100%;
      display: none;
      .close-chat {
        position: absolute;
        right: 10px;
        top: 12px;
        border: 1px solid #f7bc00;
        width: 44px;
        height: 44px;
        font-size: 20px;
        border-radius: 4px;
        z-index: 9999;
        background-color: ${color.white};
        outline: none;
        svg {
          position: absolute;
          top: 13px;
          left: 11px;
        }
      }
      
      .above-chat {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        z-index: 9999;
      }

      #chat-frame {
        width: 100%;
        height: 100%;
        background: #f7bc00;
      }
    }
  }
  
`;

export default () => (
  <Wrap id="chat-wrapper">
    <button className="live-chat" id="live-chat" onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
      <IcomoonIcon icon="chat-2" color={color.trueBlack} size={16}/>
      <span>แชทสด</span>
    </button>
    <div className="live-chat-popup" id="live-chat-popup" >
      <iframe id="chat-frame" className="chat-frame" src="about:blank" title="Chat" />
      <div className="above-chat">
        <button id="close-chat-frame-button" className="close-chat"
                onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
          <IcomoonIcon icon="close" color={color.trueBlack} size={18}/>
        </button>
      </div>

    </div>
  </Wrap>
);