import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);
  chatBox.classList.toggle('open');
  if (chatBox.classList.contains('open')) {
    chatIframe.src = process.env.FRESHCHAT_IFRAME_SRC_NO_BUTTON;
  } else {
    chatIframe.src = 'about:blank';
  }
};


const MobileContactLink = () => {
  return (
    <button className="live-chat" id="live-chat" aria-label="live-chat" onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
      <IcomoonIcon className="contact-menu-icon" icon="chat-new" color={color.white} size={30}/>
    </button>
  );
};

export default MobileContactLink;